import {useState} from "react"
import {useParams} from "react-router-dom";
import {useObjectVal} from "react-firebase-hooks/database";
import {ref} from 'firebase/database';
import {database} from '../firebase/app'
import {useRequest, useTitle} from "ahooks";
import {fetchReservationByConfirmationCode} from "../guesty";
import {Alert, Card, Spin, Tag} from 'antd'
import ContactApproval from "./Checkin/ContactApproval";
import ArrivalTimesForm from "./Checkin/ArrivalTimesForm";
import UploadPassport from "./Checkin/UploadPassport";
import Steps from "./Checkin/Steps";
import {useSetRecoilState} from "recoil";
import {guestInfoAtom} from "./Header";


const listingsWeNeedToCharge = [
    '5e64d4da3696af004760949b',
    '5ad5e2696f1dca0039f1c9f7',
    '5e64dc9c40d4fe00769d6587',
    '5e64d4da3696af0047609491',
    '5e64f14cf952770048599a5a',
    '61a111baa8aa5600345b0b5a',
    '62e6ea83fd7f5b00338d0f64',
    '5b4bb1133a17d60041316d71',
    '5dc9bd1026c04a005ae18bee',
    '5d25dbdebf8f890068b5bb7e',
    '61d96d1096f3de0037828714',
    '5dcad12a2964510046d97fde',
    '5ad5e2696f1dca0039f1ca32',
    '5ad5e26b6f1dca0039f1cc64',
    '5ad5e2696f1dca0039f1ca5d',
    '5ad5e26a6f1dca0039f1cb84',
    '62e6e8eea94357003bd7f6ae',
    '62d6c2e8ef5ce50034fd8bde',
    '6304bfbd1f2684003436d672',
    '62d91d4672cc4d0032776035'
]

export interface CheckInStep {
    text: string
    image: string
}

export interface ListingCheckInInfo {
    address: string
    steps: CheckInStep[]
}

interface HeaderMessage {
    image: string
    shouldShow: boolean
    text: string
}

interface Reservation {
    _id: string
    listingId:string
    checkOutDateLocalized: string
    checkInDateLocalized: string
    status: string
    guest: {
        fullName: string
    }
    plannedArrival: string,
    plannedDeparture: string,
    money: {
        invoiceItems: { amount: number }[]
    }
    integration: { platform: string }
    customFields: { fieldId: string, value: string | boolean }[]
}

export const getListingFirebaseRef = (listing: string) => ref(database, `checkin/listings/${listing}`);
export const getListingStepsFirebaseRef = (listing: string) => ref(database, `checkin/listings/${listing}/steps`);

const getHeaderMessageFirebaseRef = () => ref(database, `headerMessage`);


const Checkin = () => {

    const {listing, confCode} = useParams<{ listing: string, confCode: string }>()

    const [didSetPlannedTimes, setDidSetPlannedTimes] = useState(false);
    const [didNowApprovedContact, setDidNowApprovedContact] = useState(false);
    const [didNowUploadedPassport, setDidNowUploadedPassport] = useState(false);

    const [
        listingCheckInInfo,
        loadingListingCheckInInfo,
        errorListingCheckInInfo] = useObjectVal<ListingCheckInInfo>(getListingFirebaseRef(listing!));

    useTitle(listingCheckInInfo ? `${listingCheckInInfo.address} Check in` : 'Loading...');

    const [headerMessage, loadingHeaderMessage, errorHeaderMessage] = useObjectVal<HeaderMessage>(getHeaderMessageFirebaseRef())

    const setGuestInfo = useSetRecoilState(guestInfoAtom);
    const {
        data: reservation,
        loading: loadingReservation,
        error: errorReservation
    } = useRequest<Reservation, []>(() =>
            fetchReservationByConfirmationCode(confCode!)
        , {
            onSuccess: (r) => {
                setGuestInfo({fullName: r.guest.fullName});
            }
        })


    if (loadingListingCheckInInfo || loadingReservation || loadingHeaderMessage) {
        return <Spin size={'large'}/>
    }
    if (errorListingCheckInInfo || errorReservation || errorHeaderMessage) {
        return <Alert type={'error'} message={'We encountered an error. Please refresh this page later.'}/>
    }
    if (!listingCheckInInfo) {
        return <Alert type={'error'} message={'We could not find this listing.'}/>
    }
    if (!reservation) {
        return <Alert type={'error'}
                      message={'We could not find your reservation. Please contact us if you think there is a mistake.'}/>
    }

    console.log(reservation.customFields.map(c=>c.fieldId).sort((a,b)=>a>b?1:-1))

    let reservationPaid = false;
    const checkoutDate = new Date(reservation.checkOutDateLocalized);
    const checkinDate = new Date(reservation.checkInDateLocalized);
    const guestName = reservation.guest.fullName;
    const plannedArrival = reservation.plannedArrival;
    const plannedDeparture = reservation.plannedDeparture;
    const totalMoney = reservation.money.invoiceItems.map(({amount}) => amount).reduce((a, b) => a + b);
    const isAirbnb = reservation.integration.platform.includes("airbnb");
    const isBooking = reservation.integration.platform.toLocaleLowerCase().includes("bookingcom");
    const customFields = reservation.customFields || [];
    const approvedContractField = customFields.filter((cf) => cf.fieldId === '623472980da1820033e9924e')[0];
    const didApproveContractInThePast = approvedContractField && approvedContractField.value === true;
    const paidField = customFields.filter((cf) => cf.fieldId === '5ae8e8214c25d30064380d8f')[0];
    const wasPaid = paidField && paidField.value === true;
    const virtualCardField = customFields.filter((cf) => cf.fieldId === '6234aea59011ad0031c60536')[0];
    const isVirtualCard = virtualCardField && virtualCardField.value === true;
    const isIsraeliField = customFields.filter((cf) => cf.fieldId === '5b06ea3af5e8050024c872f8')[0];
    const isIsraeli = isIsraeliField && isIsraeliField.value === true;
    const paidVatField = customFields.filter((cf) => cf.fieldId === '5dcd7c0fca33d0001f124ded')[0];
    const wasPaidVat = paidVatField && paidVatField.value === true;
    const vatWaivedField = customFields.filter((cf) => cf.fieldId === '63d5c645dad6540043ac8dfa')[0];
    const wasVatWaived = vatWaivedField && vatWaivedField.value === true;
    const passportUploadedField = customFields.filter((cf) => cf.fieldId === '5af0702c5a28170024627ae7')[0];
    const wasPassportUploaded = passportUploadedField && passportUploadedField.value === true;
    const isConfirmed = reservation.status === "confirmed";

    let shouldPayVat = isIsraeli && !wasPaidVat;
    if (shouldPayVat && wasVatWaived) {
        shouldPayVat = false
    }
    const vatAmount = Math.round(totalMoney * .17);
    const isAfterCheckout = Number(checkoutDate) + 24 * 60 * 60 * 1000 < Date.now()// we add one more day to be safe.;
    const isCheckInSoon = Number(checkinDate) - 3 * 24 * 60 * 60 * 1000 < Date.now();
    const didApproveContract = didApproveContractInThePast || didNowApprovedContact;


    const isSelfPaidBookingCom =isBooking && !listingsWeNeedToCharge.includes(reservation.listingId);
    console.log({isSelfPaidBookingCom,p:reservation.integration.platform})
    if (wasPaid || isAirbnb || isVirtualCard || isSelfPaidBookingCom) {
        reservationPaid = true;
    }

    if (!isConfirmed) { // tested
        return <Alert type={'warning'}
                      message={'Reservation is not confirmed. Contact us if you think this is a mistake.'}/>
    }

    if (isAfterCheckout) { // tested
        return <Alert type={'warning'} message={'Page is not visible after checkout time.'}/>
    }

    if (!reservationPaid) {
        return <Alert type={'warning'} message={<div>
            <p>
                {guestName}, We still didn't verified the payment.
            </p>
            <p>Once it is verified, the instructions will be
                visible. Make sure to visit this page again few days before the check in date.
            </p>
            <p>
                If you believe there is a mistake, please contact us.
            </p>
        </div>}/>

    }
    if (!didApproveContract) {
        return <ContactApproval listing={listing!} reservationId={reservation._id} onApproved={() => {
            setDidNowApprovedContact(true)
        }}/>
    }
    if ((!plannedArrival || !plannedDeparture) && !didSetPlannedTimes) {
        return <ArrivalTimesForm isCheckInSoon={isCheckInSoon} reservationId={reservation._id}
                                 onArrivalTimesSet={() => {
                                     setDidSetPlannedTimes(true)

                                 }}/>
    }
    if (!isCheckInSoon) {
        return <Alert type={'info'}
                      message={'All Set! Check-in instructions will be visible 3 days before check-in.'}/>
    }

    if (!wasPassportUploaded && !didNowUploadedPassport) {
        return <UploadPassport onPassportUploaded={() => {
            setDidNowUploadedPassport(true)
        }} reservationId={reservation._id} isIsraeli={isIsraeli}/>
    }

    if (shouldPayVat) {
        return <div>
            <div style={{direction: "rtl"}}>על מנת לצפות בהוראות הכניסה לדירה, יש לשלם מע"מ על
                סך <strong>{vatAmount} ש"ח </strong> כנדרש בחוק.

                <p>
                    הדרך הפשוטה ביותר היא תשלום באמצעות שירות התשלומים של אירביאנדבי בלינק הבא:

                    <a target={"_blank"} href={"https://www.airbnb.com/resolutions"}>תשלום באירבנבי</a>
                </p>
                <p>
                    ניתן גם לשלם באמצעות הפייבוקס למספר 0584319083 - בבקשה לא להתקשר או לשלוח הודעות למספר הזה.

                    יש לעדכן אותנו באירבנבי לאחר ששילמת.
                </p>
                <p>
                    אם אתה מאמין שחלה טעות, נא פנה אלינו בצ'אט של אירבנב.
                </p>
            </div>
            <div>{guestName}, We are still waiting for your VAT payment ({vatAmount} ILS). Please make sure to pay it in
                order to see the
                check-in
                instructions.
                <p>
                    The easiest way is by Airbnb resolution center using the link below:
                    <a target={"_blank"} href={"https://www.airbnb.com/resolutions"}>Pay with Airbnb</a>
                </p>
                <p>
                    You can also use Paybox transfer to the number 0584319083 - please do not call or send messages to this number.
                </p>
                <p>
                    If you believe there is a mistake, please contact us using Airbnb chat.
                </p>
            </div>
        </div>
    }

    let steps = listingCheckInInfo.steps;
    if (headerMessage?.shouldShow) {
        steps = [{text: headerMessage.text, image: headerMessage.image}, ...steps]
    }
    return <div style={{gap: '.5rem', display: 'flex', flexDirection: "column"}}>
        <Card>
            <h2>
                Check-in instructions to:
            </h2>
            <h1>
                {listingCheckInInfo.address}
            </h1>
        </Card>
        <Steps steps={steps}/>
        <Alert type={'info'} message={<div>
            Next time book directly at <a
            href='https://book.stk-properties.com'>book.stk-properties.com</a> &nbsp;

            and use this free coupon <Tag>I-LOVE-TEL-AVIV</Tag> (7% off)
        </div>}/>
    </div>
}
export default Checkin;
